import { get, once } from 'lodash';
import {
  createLogger,
  initializeNavigationHandlerForEditor,
  doRouting,
  createControllerId,
  onLocationChange,
  isFrameless,
} from '../../common/controller/helpers';
import getEnvironment from '../../common/services/get-environment';
import { getSectionUrl } from '../services/get-section-url';
import { BLOG_POST_PAGE_SECTION_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { createReduxStore } from './create-redux-store';
import reducers from '../reducers';
import { createRouter } from './create-router';
import { createPostPageApi } from './create-post-page-api';
import { handleProvisioning } from '../../common/services/handle-provisioning';
import { handleError } from '../../common/store/debug-state/handle-error';
import {
  initializeActions,
  initializePromisifiedActions,
  initializeStoreBaseData,
  refreshDataOnLogin,
} from './init-actions';
import { POST_WIDGET_NAME } from '../../viewer-script/constants/widgets';
import { getPathname } from '../../common/store/location/get-pathname';
import { setRouterMatch } from '../../common/router';
import { createMonitoring } from '../../common/services/create-monitoring';
import { createPerformanceTracker } from '../../common/services/performance-tracker';
import { subscribeToChange } from '../../common/services/state-optimizer';
import { simulateControllerError } from '../../common/services/simulate-error';
import { fetchExperiments } from '../../common/store/experiments/experiments-actions';
import { isRtlLanguage } from '../../common/services/is-rtl-language';
import listenToSettingsChange from '../../common/services/listen-to-settings-change';
import { getInitialStateVersions } from '../../common/services/state-optimizer/change-detector';
import { initLazyActions } from '../../common/controller/lazy-actions';
import { isInPostPreview } from '../../common/services/detect-route';
import { getCurrentSiteLanguage } from '../../common/services/get-current-site-language';

const isProduction = process.env.NODE_ENV === 'production';

const getSectionPathnameAfterLogin = async (wixCodeApi, appParams) =>
  getPathname(
    wixCodeApi.location,
    await getSectionUrl({
      wixCodeApi,
      instanceId: appParams.instanceId,
      sectionId: BLOG_POST_PAGE_SECTION_ID,
    }),
  );

export const createPostPageController = (
  { appParams, compId, config, setProps, wixCodeApi, platformAPIs, type },
  allCtrls,
  context,
) => {
  const { isEditor, isPreview, isSSR, isDebug } = getEnvironment(wixCodeApi);

  const { sentry, captureToSentry, fedopsLogger } = createMonitoring({
    origin: `app-worker-${POST_WIDGET_NAME}`,
    platformAPIs,
    appParams,
    wixCodeApi,
    type,
  });

  fedopsLogger.appLoadStarted();
  const fedopsAppLoaded = once(() => fedopsLogger.appLoaded());

  const language = getCurrentSiteLanguage(wixCodeApi);

  const bundleName = 'post-page';

  const log = createLogger(isDebug, isProduction);
  const perf = createPerformanceTracker(bundleName, { isDebug, isSSR });

  log('createPostPageController', { appParams, allCtrls, wixCodeApi, isSSR, language });

  const pageReady = async () => {
    let store;
    try {
      const pageReadyMarker = perf.trackStart(`${new Date().toISOString().slice(11)} pageReady`);
      log('createPostPageController.pageReady -> start');

      const sectionUrl = await getSectionUrl({
        wixCodeApi,
        instanceId: appParams.instanceId,
        sectionId: BLOG_POST_PAGE_SECTION_ID,
      });

      simulateControllerError(wixCodeApi, 'post-page.pageReady');
      // eslint-disable-next-line
      let router;
      const getRouter = () => router;

      const getSectionPathname = (store) =>
        isFrameless({ store, wixCodeApi })
          ? getPathname(wixCodeApi.location, sectionUrl)
          : '/' + wixCodeApi.location.path.join('/');

      initLazyActions({ staticsBaseUrl: appParams.baseUrls.staticsBaseUrl, isPreview, isEditor, isSSR });

      let marker = perf.trackStart('createReduxStore', pageReadyMarker);
      store = createReduxStore({
        appParams,
        wixCodeApi,
        compId,
        reducers,
        fedopsLogger,
        getRouter,
        platformAPIs,
        isSSR,
        sentry,
        isEditor,
        isPreview,
        language,
        bundleName,
      });
      perf.trackEnd(marker);

      if (isEditor || isPreview) {
        await store.dispatch(fetchExperiments(context.experimentsPromise));
      }

      marker = perf.trackStart('initializeActions', pageReadyMarker);
      const actions = initializeActions({
        wixCodeApi,
        store,
        fedopsLogger,
        fedopsAppLoaded,
        config,
        platformAPIs,
        setProps,
        type,
        compId,
        isEditor,
        isPreview,
        staticsBaseUrl: appParams.baseUrls.staticsBaseUrl,
      });
      const actionsPromisified = initializePromisifiedActions({ wixCodeApi, compId, store });
      perf.trackEnd(marker);

      marker = perf.trackStart('createRouter', pageReadyMarker);
      router = createRouter(store, config, wixCodeApi, compId);
      perf.trackEnd(marker);

      const route = get(router.matchPath(getSectionPathname(store)), 'route');

      await perf.trackPromise(
        'initializeStoreBaseData',
        () =>
          initializeStoreBaseData({
            wixCodeApi,
            store,
            language,
            platformAPIs,
            config,
            context,
            bundleName,
            fedopsAppName: 'communities-blog-post-page',
            translationsName: 'main',
            preFetch: () => router.preFetch(getSectionPathname(store)),
            allowPreviewInstance: isInPostPreview(route),
          }),
        pageReadyMarker,
      );

      onLocationChange(wixCodeApi, ({ path }) => {
        log('navigated to new path', path);
        doRouting({
          store,
          router,
          pathname: isFrameless({ store, wixCodeApi }) ? getSectionPathname(store) : '/' + path.join('/'),
          isInitialLoad: false,
        });
      });

      await perf.trackPromise(
        'doRouting',
        () =>
          doRouting({
            store,
            router,
            pathname: getSectionPathname(store),
            isInitialLoad: true,
          }),
        pageReadyMarker,
      );

      log('createPostPageController.pageReady -> done');
      const state = store.getState();

      // after initial routing is done, we subscribe to get routing change as fast as route changes, without waiting for handler to resolve
      router.onMatch((match) => store.dispatch(setRouterMatch(match)));

      const stateVersions = getInitialStateVersions(state);
      const controllerId = createControllerId();

      setProps({
        state,
        stateVersions,
        actions,
        actionsPromisified,
        cssBaseUrl: appParams.baseUrls.staticsBaseUrl,
        isSSR,
        isRTL: isRtlLanguage(language),
        controllerId,
      });

      if (isSSR) {
        fedopsAppLoaded();
      }

      if (isEditor || isPreview) {
        listenToSettingsChange(store);
        initializeNavigationHandlerForEditor({ store, router });
      }

      refreshDataOnLogin({
        wixCodeApi,
        store,
        router,
        getPathname: () => getSectionPathnameAfterLogin(wixCodeApi, appParams),
        doRouting,
      });
      subscribeToChange(store, stateVersions, setProps, controllerId);
      perf.trackEnd(pageReadyMarker);
    } catch (error) {
      handleError({ controller: POST_WIDGET_NAME, store, setProps, appParams, captureToSentry, isDebug, isSSR })(error);
    }
  };

  return Promise.resolve({
    pageReady: () =>
      isEditor ? handleProvisioning(appParams, fedopsLogger, wixCodeApi, setProps, pageReady) : pageReady(),
    exports: () => createPostPageApi(appParams.instance, wixCodeApi),
  }).catch(console.error);
};
