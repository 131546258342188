import { get } from 'lodash';
import { initialiseInstanceValues } from '../../common/store/instance-values/instance-values-actions';
import { getInstanceId } from '../../common/store/instance-values/instance-values-selectors';
import { fetchExperiments } from '../../common/store/experiments/experiments-actions';
import { fetchTopology } from '../../common/store/topology/topology-actions';
import { fetchTranslations } from '../../common/store/translations/translations-actions';
import { setLocale } from '../../common/store/locale/locale-actions';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { setBasicParams } from '../../common/store/basic-params/basic-params-actions';
import { setAppConfig } from '../../common/store/app-config/app-config-actions';
import { fetchPostListPosts } from '../actions/fetch-post-list-posts';
import { initWixData } from '../../common/actions/init-wix-data';
import { getPostListWidgetPageSize } from '../selectors/post-list-widget-page-size';
import fetchCategories from '../../common/store/categories/fetch-categories';
import { getInstance } from '../../common/controller/helpers';
import { fetchInitialData } from '../actions/fetch-initial-data';
import { setCommunitiesContext } from '../../common/store/communities-context/communities-context-actions';

export {
  initializeActions,
  initializePromisifiedActions,
  refreshDataOnLogin,
} from '../../common/controller/init-actions';

export async function initializeStoreBaseData({
  wixCodeApi,
  store,
  language,
  platformAPIs,
  config,
  bundleName,
  fedopsAppName,
  translationsName,
  context = {},
}) {
  const viewMode = wixCodeApi.window.viewMode.toLowerCase();

  store.dispatch(initialiseInstanceValues(getInstance(wixCodeApi)()));

  const instanceId = getInstanceId(store.getState());

  const useAggregator = get(wixCodeApi, 'location.query.useAggregator');
  if (useAggregator) {
    await Promise.all([
      store.dispatch(initWixData(viewMode, ['categoryId', 'tagId'], config)),
      store.dispatch(setLocale(language)),
      store.dispatch(setAppSettings({ style: config.style.styleParams })),
      store.dispatch(setBasicParams({ viewMode, language, biPageNumber: platformAPIs.bi.pageNumber })),
      store.dispatch(setAppConfig({ bundleName, fedopsAppName })),
    ]);

    await store.dispatch(fetchInitialData());
    await store.dispatch(fetchTopology(instanceId));
    return;
  }

  await store.dispatch(initWixData(viewMode, ['categoryId', 'tagId'], config));
  await store.dispatch(fetchCategories());

  await Promise.all([
    store.dispatch(fetchExperiments(context.experimentsPromise)).then(() => store.dispatch(fetchTopology(instanceId))),
    store.dispatch(setCommunitiesContext()),
    store.dispatch(fetchTranslations(language, translationsName)),
    store.dispatch(setLocale(language)),
    store.dispatch(setAppSettings({ style: config.style.styleParams })),
    store.dispatch(fetchPostListPosts(1, getPostListWidgetPageSize(store.getState()))),
  ]);

  await store.dispatch(setBasicParams({ viewMode, language, biPageNumber: platformAPIs.bi.pageNumber }));
  await store.dispatch(setAppConfig({ bundleName, fedopsAppName }));
}
